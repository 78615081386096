<template>
  <div class="about_index">
    <div class="banner">{{ '客户服务' | sLang }}</div>
    <div class="about-content w1200">
        <div class="menu-wrap left">
          <div class="menu-head">{{ '客户服务' | sLang }}</div>
          <el-menu
            :default-active="menuList[menuIndex1].secondMenu[menuIndex2].indexNum2"
            :default-openeds="openeds"
            class="el-menu-vertical-demo"
            @select="handleSelect"
            background-color="#FEF6E9"
            text-color="#353638"
            active-text-color="#353638">
            <el-submenu :index="item1.indexNum1" v-for="(item1, index1) in menuList" :key="item1.indexNum1">
              <template slot="title">
                <span>{{ item1.oneTit | sLang }}</span>
              </template>
              <el-menu-item :index="item2.indexNum2" v-for="(item2,menuIndex2) in item1.secondMenu" :key = "item2.indexNum2">
                {{ item2.tit | sLang }}
                <i class="el-icon-arrow-right"></i>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
        <div class="right-con left" v-for="(item1,i) in menuList" :key="item1.indexNum1" v-show="i===menuIndex1">
          <template v-for="(item2,menuIndex2) in item1.secondMenu">
            <div class="tit" :id="'anchors-'+item2.indexNum2">{{ item2.tit | sLang }}</div>
            <div class="right-img" :style="{ backgroundImage: 'url('+itemImg(item2.indexNum2)+')'}"  v-lazy:background-image="itemImg(item2.indexNum2)">
              <span>{{ item2.text | sLang }}</span>
            </div>
          </template>
        </div>
    </div>
    <my-foot @changePage="changePage" id="anchor_3" />
  </div>
</template>

<script>
import '../../assets/css/service/index.scss'
import myFoot from '@/components/myFoot/index.vue'
export default {
  name: 'service',
  data() {
    return {
      menuList: [
        {
          oneTit: '个人服务',
          indexNum1: '1',
          secondMenu: [
            { tit: '保障咨询', text: '以高净值家庭五大保障场景为基础，利用大数据平台智能算法，识别不同家庭生命周期及不同财富水平下的各类家庭面临的风险类型，为不同类型家庭提供专业化的需求分析建议。', imgName: 'firstMenu_0', indexNum2: '1-1' },
            { tit: '保单检视', text: '动态保单检视服务将保单梳理、需求再分析、配置再升级进行结合，定期排查客户场景化需求的变化，依托丰富的家庭保障配置经验，为高净值家庭提供不断“进化”的综合保障建议。', imgName: 'firstMenu_1', indexNum2: '1-2' },
            { tit: '保险配置', text: '依托我司丰富的保险货架，专业的保险专家团队，为客户提供专属家庭综合保障方案。同时提供方案出具、产品对比、方案解读、协助投保、核保咨询、承保跟进等售中全流程服务，注重客户体验及服务效率。', imgName: 'firstMenu_2', indexNum2: '1-3' },
            { tit: '管家陪伴', text: '打通售后服务全流程，包括协助保全、理赔办理、续期缴费、权益申领等一系列管家级陪伴服务，在服务流程中提质增效，确保客户在每一个环节都能体验到专业贴心的服务。', imgName: 'firstMenu_3', indexNum2: '1-4' }
          ]
        },
        {
          oneTit: '渠道服务',
          indexNum1: '2',
          secondMenu: [
            { tit: '产品支持', text: '全面的保险货架支持，一方面获取保司合约，为机构提供经纪渠道标准产品；另一方面，在与特定保司深度合作的基础上，开发具有明显优势的独家定制产品，满足市场化保险配置需求，市场覆盖全、保司合作广、产品更丰富。', imgName: 'secondMenu_0', indexNum2: '2-1' },
            { tit: '专属赋能', text: '针对渠道机构，安排专属要客渠道经理进行专属维护及赋能支持，支持渠道实现平台保险服务信息化及保险配置综合解决方案的实施，持续提升渠道机构满意度，以长期发展的原则提供高质量服务。', imgName: 'secondMenu_1', indexNum2: '2-2' },
            { tit: '运营支持', text: '基于合作协议签署、建立合作关系，进行客户转介，推动渠道机构产品及服务解决方案的优化，实现产品+运营+系统全闭环管理及体系化运营支持，提供从保单签约到保单售后一系列完整的综合服务，支持渠道进行客户维护及保单服务。', imgName: 'secondMenu_2', indexNum2: '2-3' }
          ]
        }
      ],
      openeds: ['1', '2'], // 导航栏菜单默认开启项
      showContant: { tit: '保障咨询', text: '以高净值家庭五大保障场景为基础，利用大数据平台智能算法，识别不同家庭生命周期及不同财富水平下的各类家庭面临的风险类型，为不同类型家庭提供专业化的需求分析建议。', imgName: 'firstMenu_0', indexNum2: '1-1' }, // 右边显示图片和文字
      menuIndex1: 0, // 当前打开的一级菜单索引
      menuIndex2: 0
      // anchorsLocation:[], //所有锚点的位置
    }
  },
  components: {
    myFoot
  },
  mounted() {
    this.handleSelect('1-1')
    // this.getAnchorsLocation()
    window.addEventListener('scroll', this.pageScrolling)
  },
  beforeDestroy(){
    window.removeEventListener("scroll", this.pageScrolling);
  },
  methods: {
    // 图片路径
    itemImg(id) {
      return require(`@/assets/img/service/about_con_${id}.png`)
    },
    changePage(i) {
      this.$store.commit('changePage', i)
    },
    // 左侧菜单栏选择
    handleSelect(key, keyPath) {
      let arr = key.split('-')
      this.menuIndex1 = (arr[0] * 1) - 1
      this.menuIndex2 = (arr[1] * 1) - 1
      this.showContant = this.menuList[this.menuIndex1].secondMenu[this.menuIndex2]
      this.gotoLocation(this.showContant.indexNum2)
      if(!this.showContant.location){
        this.getAnchorsLocation()
      }
    },
    // 获取所有模块定位位置
    getAnchorsLocation(){
      this.$nextTick(() => {
        let { secondMenu } = this.menuList[this.menuIndex1];
        secondMenu.forEach((key, i) => {
          secondMenu[i].location= document.getElementById('anchors-' + key.indexNum2).offsetTop - 60;
        })
      })
      
    },
    // 滚动条举例监听
    pageScrolling(){
      this.$nextTick(() => {
        let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
        this.menuList[this.menuIndex1].secondMenu.forEach((key, i) => {
          if(scrollTop > key.location-200){
            this.menuIndex2 = i;
          }
        })
        console.log('=============')
      })
    },
    // 锚点定位
    gotoLocation(i) {
      this.$nextTick(() => {
        if (i === '1-1' || i === '2-1') {
          document.documentElement.scroll({ top: 0, behavior: 'smooth' })
          // document.documentElement.scrollTop = go
          document.body.scrollTop = 0
          return
        }
        let anchors = document.getElementById('anchors-' + i)
        if (anchors) {
          let go = anchors.offsetTop - 60
          document.documentElement.scroll({ top: go, behavior: 'smooth' })
          // document.documentElement.scrollTop = go
          document.body.scrollTop = go
        }
      })
    }
  },
}
</script>
